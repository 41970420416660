import React from "react"
import { Link } from "gatsby"
import CareerForm from "../components/CareerForm"
import Layout from "../components/layout"
import Newsletter from "../components/Newsletter"
//import SEO from "../components/seo"

const CareersPage = ({ data }) => (
  <Layout>
    <div className="pt-24 md:pt-32 lg:pt-32 xl:pt-32">
      <Link className="" to="/">
        <div className="flex items-center mx-5 md:mx-10 lg:mx-12 xl:mx-20 mb-5 md:mb-8 lg:mb-12 xl:mb-12 pt-12 border-b-2 lg:border-b-4 pb-5 lg:pb-10 border-amber-400">
          <svg
            class="w-6 h-6 lg:w-10 lg:h-10"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <h1 className="pl-6 lg:pl-10 text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
            Apply below for future hirings.
          </h1>
        </div>
      </Link>
    </div>
    <CareerForm />
    <Newsletter />
  </Layout>
)

export default CareersPage
